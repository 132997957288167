export const BANKS: string[] = [
  "Banque Extérieure d'Algérie (BEA)",
  "Banque Nationale d'Algérie (BNA)",
  "Banque de l'Agriculture et du Développement Rural (BADR)",
  "Banque de Développement Local (BDL)",
  "Crédit Populaire d'Algérie (CPA)",
  "Caisse Nationale d'Épargne et de Prévoyance (CNEP Banque)",
  "Banque Nationale de l'Habitat",
  "Banque Al Baraka Algérie",
  "Arab Banking Corporation Algérie (ABC Algérie)",
  "Natixis Algérie",
  "Société Générale Algérie",
  "Citibank N.A. Algérie",
  "Arab Bank PLC Algérie",
  "BNP Paribas El Djazaïr",
  "Gulf Bank Algeria (AGB)",
  "Housing Bank for Trade and Finance - Algeria (HBTF-Algeria)",
  "Fransabank El Djazaïr",
  "Al Salam Bank - Algeria (ASBA)",
  "HSBC Algeria",
];
